import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import i18next from 'i18next'
import { uiText } from './locale/ui'
import LanguageDetector from 'i18next-browser-languagedetector'

i18next.use(LanguageDetector).init({
  supportedLngs: ['pl', 'en'],
  fallbackLng: 'en',
  order: [
    'navigator',
    'htmlTag',
    'querystring',
    'cookie',
    'localStorage',
    'sessionStorage',
    'path',
    'subdomain',
  ],
  caches: ['localStorage', 'cookie'],
  resources: { ...uiText },
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
