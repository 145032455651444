import css from './TextAreaForm.module.css'
import { useState } from 'react'
import Label from '../Label/Label'
import Button from '../Button/Button'
import { hintsKeys } from '../../locale/suggestions'
import i18next from 'i18next'

function content() {
  return {
    QUESTION: {
      label: i18next.t('gameplay.input.question.label'),
      placeholder: i18next.t('gameplay.input.question.placeholder'),
      buttonDisabledText: i18next.t('gameplay.button.question.disabled'),
      buttonText: i18next.t('gameplay.button.question.enabled'),
    },
    ANSWER: {
      label: i18next.t('gameplay.input.answer.label'),
      placeholder: i18next.t('gameplay.input.answer.placeholder'),
      buttonDisabledText: i18next.t('gameplay.button.answer.disabled'),
      buttonText: i18next.t('gameplay.button.answer.enabled'),
    },
  }
}

function TextAreaForm(props) {
  const { callback, type, suggestions = [] } = props
  const [value, setValue] = useState('')
  const [suggestedIndex, setSuggestedIndex] = useState(0)

  function triggerSuggestion() {
    const maxIndex = suggestions.length - 1
    const hintKey = hintsKeys[suggestions[suggestedIndex]]

    if (maxIndex === suggestedIndex) {
      setValue(i18next.t(`hints.${hintKey}`))
      setSuggestedIndex(0)
      return
    }

    setValue(i18next.t(`hints.${hintKey}`))
    setSuggestedIndex(suggestedIndex + 1)
  }

  function updateInput(event) {
    setValue(event.target.value)
  }

  function handleSubmit(event) {
    event.preventDefault()
    setValue('')
    callback(value, type)
  }

  function isDisabled() {
    if (type === 'QUESTION') {
      return value.trim().length <= 1
    }
    return value.trim().length <= 0
  }

  return (
    <form
      className={css.TextAreaForm}
      onSubmit={(e) => {
        e.preventDefault()
      }}
    >
      <Label text={content()[type].label} />
      <div className={css.TextAreaWrap}>
        <textarea
          id={type}
          name={type}
          value={value}
          placeholder={content()[type].placeholder}
          onChange={updateInput}
          maxLength={240}
        />
      </div>
      <div className={css.DynamicButtonWrapper}>
        <Button
          click={triggerSuggestion}
          text={i18next.t('gameplay.button.hint.enabled')}
          disabled={type !== 'QUESTION'}
        />

        <Button
          click={handleSubmit}
          text={
            isDisabled()
              ? content()[type].buttonDisabledText
              : content()[type].buttonText
          }
          disabled={isDisabled()}
        />
      </div>
    </form>
  )
}

export default TextAreaForm
