import css from './Lobby.module.css'
import SingleInputForm from '../../elements/SingleInputForm/SingleInputForm'
import Button from '../../elements/Button/Button'
import Guide from '../../elements/Guide/Guide'
import Heading from '../../elements/Heading/Heading'
import WaitingPlayers from '../../elements/WaitingPlayers/WaitingPlayers'
import i18next from 'i18next'

function Lobby(props) {
  const { players, gameId, isPrime, humanId, callback, initialized } = props

  async function joinAsNewPlayer(gameId, humanId, humanAlias) {
    const url = `${process.env.REACT_APP_API_URL}/game`
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        human: { id: humanId, alias: humanAlias },
        gameId: gameId,
        game: { id: gameId, status: null },
      }),
    })
    return await response.json()
  }

  async function startGameAsPrimePlayer(gameId, humanId) {
    const url = `${process.env.REACT_APP_API_URL}/game/progress`
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        human: { id: humanId },
        game: {
          id: gameId,
          status: 'PROGRESSING',
          setup: { modes: ['VOTING'] },
        },
      }),
    })
    return await response.json()
  }

  function collectPlayerAlias(alias) {
    joinAsNewPlayer(gameId, humanId, alias)
      .then(({ data }) => {
        callback(data)
      })
      .catch(() => {
        // TODO: Give options, display meaningful info on FE
        // player may join next, ot return to current game
        callback(null)
      })
  }

  function playerAlreadyJoined() {
    let playerFound = false

    for (const player of players) {
      if (player.id === humanId) {
        playerFound = true
      }
    }

    return playerFound
  }

  function onStartButtonClick() {
    startGameAsPrimePlayer(gameId, humanId)
      .then(({ data }) => {
        initialized(data)
      })
      .catch(() => {
        // TODO: Give options, display meaningful info on FE
        // prime can start only one game at once
        initialized(null)
      })
  }

  return (
    <div className={css.Lobby}>
      <Heading text={i18next.t('lobby.heading.title')} />

      {isPrime && players.length === 1 ? (
        <Guide text={i18next.t('lobby.guide.primeAlone')} />
      ) : (
        <Guide text={i18next.t('lobby.guide.invited')} />
      )}

      <WaitingPlayers players={players} gameId={gameId} />

      {isPrime && (
        <div className={css.ButtonWrapper}>
          <Button
            click={onStartButtonClick}
            disabled={players.length <= 2}
            text={
              players.length <= 2
                ? i18next.t('lobby.button.prime.disabled')
                : i18next.t('lobby.button.prime.enabled')
            }
          />
        </div>
      )}

      {!isPrime && playerAlreadyJoined() && (
        <div className={css.ButtonWrapper}>
          <Button
            disabled={true}
            text={i18next.t('lobby.button.waiting.disabled')}
          />
        </div>
      )}

      {!playerAlreadyJoined() && (
        <SingleInputForm
          label={i18next.t('lobby.input.name.label')}
          placeholder={i18next.t('lobby.input.name.placeholder')}
          buttonTextDisabled={i18next.t('lobby.button.invited.disabled')}
          buttonText={i18next.t('lobby.button.invited.enabled')}
          callback={collectPlayerAlias}
        />
      )}
    </div>
  )
}

export default Lobby
