import { hints } from './suggestions'
import { animalNames } from './animals'

export const uiText = {
  en: {
    translation: {
      gameCreation: {
        heading: {
          title: 'Who said we know each other?',
        },
        guide: {
          main: 'Create a game where everyone asks a question and everyone answers it. Then, players will vote on the answers without knowing who wrote them. In the end, reveal the results to show what connects you and what divides you.',
        },
        input: {
          name: {
            label: 'Name',
            placeholder: "That's what they call you...",
          },
        },
        button: {
          cta: {
            disabled: 'Enter your name first',
            enabled: 'Create game',
          },
        },
      },
      lobby: {
        heading: {
          title: 'Who said we know each other?',
        },
        guide: {
          primeAlone:
            'Invite at least 2 friends to start the game. Use the "+ Invite someone" button to copy the invitation link.',
          invited:
            'Everyone asks a question and everyone answers. Then we vote on the answers without knowing who wrote them. Finally, the results are revealed, showing what connects us and what divides us.',
        },
        input: {
          name: {
            label: 'Name',
            placeholder: 'That’s what they call you...',
          },
        },
        button: {
          prime: {
            disabled: 'Let others join...',
            enabled: 'Start game',
          },
          invited: {
            disabled: 'Enter your name...',
            enabled: 'Join',
          },
          invite: {
            enabled: '+ Invite someone',
          },
          waiting: {
            disabled: 'Waiting for others...',
          },
        },
        list: {
          players: 'Players',
        },
      },
      gameplay: {
        heading: {
          question: 'Ask about anything!',
          answer: 'In exchange for answers',
          done: 'You’re done',
        },
        guide: {
          main: "Open-ended questions provide better answers – avoid ones that can be answered with just 'yes' or 'no'. You’re still anonymous.",
          first:
            'PS. You’re the first person to ask a question! You’ll also need to answer your own question.',
          waiting:
            'Let’s wait for those still thinking... Green bars represent questions that have received all answers.',
        },
        input: {
          question: {
            label: 'Question',
            placeholder: 'Write your question here...',
          },
          answer: {
            label: 'Answer',
            placeholder: 'Write your answer here...',
          },
        },
        button: {
          question: {
            disabled: 'Ask a question first',
            enabled: 'Ask everyone',
          },
          answer: {
            disabled: 'Answer first',
            enabled: 'Submit answer',
          },
          hint: {
            enabled: 'Suggest',
          },
        },
        list: {
          question: 'Question',
        },
      },
      voting: {
        heading: {
          main: 'Vote for yourself',
          done: 'You’re done',
        },
        guide: {
          done: 'The results will be displayed soon. Green bars represent people who have finished voting.',
        },
        button: {
          back: 'Back',
          next: 'Next',
        },
        list: {
          question: 'Question',
          answers: 'Answers',
        },
      },
      results: {
        heading: {
          main: 'Voting Results',
          all: 'All Answers',
        },
        button: {
          reset: 'Reset',
          all: 'Show All',
        },
        list: {
          votes: 'Most Votes',
          connected: 'Best Connections',
          question: 'Question',
          answers: 'Answers',
        },
      },
      hall: {
        heading: {
          main: 'Hall of Fame',
        },
        guide: {
          main: 'Recognition for those who contributed during app testing. Random order.',
        },
        button: {
          close: 'Close',
        },
        label: {
          alpha: 'Alpha Participants',
          special: 'Special Thanks',
        },
      },
      hints: { ...hints.en },
      animals: { ...animalNames.en },
    },
  },
  pl: {
    translation: {
      gameCreation: {
        heading: {
          title: 'Kto powiedział, że się znamy?',
        },
        guide: {
          main: 'Utwórz grę, w której każdy zada pytanie i każdy na nie odpowie. Następnie gracze będą głosować na odpowiedzi, nie wiedząc, kto je napisał. Na końcu ujawnisz wyniki, pokazując, co Was łączy, a co dzieli.',
        },
        input: {
          name: {
            label: 'Imię',
            placeholder: 'Tak Cię nazywają...',
          },
        },
        button: {
          cta: {
            disabled: 'Najpierw podaj imię',
            enabled: 'Stwórz grę',
          },
        },
      },
      lobby: {
        heading: {
          title: 'Kto powiedział, że się znamy?',
        },
        guide: {
          primeAlone:
            'Zaproś co najmniej 2 znajomych, aby rozpocząć grę. Użyj przycisku "+ Zaproś kogoś", by skopiować link z zaproszeniem.',
          invited:
            'Każdy zadaje pytanie i każdy odpowiada. Następnie głosujemy na odpowiedzi, nie wiedząc, kto je napisał. Na końcu ujawniane są wyniki, pokazując, co nas łączy, a co dzieli.',
        },
        input: {
          name: {
            label: 'Imię',
            placeholder: 'Tak Cię nazywają...',
          },
        },
        button: {
          prime: {
            disabled: 'Daj innym dołączyć...',
            enabled: 'Wystartuj grę',
          },
          invited: {
            disabled: 'Podaj imię...',
            enabled: 'Dołącz',
          },
          invite: {
            enabled: '+ Zaproś kogoś',
          },
          waiting: {
            disabled: 'Czekamy na innych...',
          },
        },
        list: {
          players: 'Gracze',
        },
      },
      gameplay: {
        heading: {
          question: 'Pytaj o cokolwiek!',
          answer: 'W zamian za odpowiedzi',
          done: 'Skończyłeś',
        },
        guide: {
          main: "Pytania otwarte dają lepsze odpowiedzi – unikaj takich, na które można odpowiedzieć tylko 'tak' lub 'nie'. Wciąż jesteś anonimowy.",
          first:
            'PS. Jesteś pierwszą osobą, która zadała pytanie! Na swoje także trzeba odpowiedzieć.',
          waiting:
            'Poczekajmy na tych, którzy jeszcze myślą... Zielone paski reprezentują pytania, które uzyskały wszystkie odpowiedzi.',
        },
        input: {
          question: {
            label: 'Pytanie',
            placeholder: 'Tutaj napisz pytanie...',
          },
          answer: {
            label: 'Odpowiedź',
            placeholder: 'Tutaj napisz odpowiedź...',
          },
        },
        button: {
          question: {
            disabled: 'Najpierw zadaj pytanie',
            enabled: 'Spytaj wszystkich',
          },
          answer: {
            disabled: 'Najpierw odpowiedz',
            enabled: 'Zatwierdź odpowiedź',
          },
          hint: {
            enabled: 'Losuj',
          },
        },
        list: {
          question: 'Pytanie',
        },
      },
      voting: {
        heading: {
          main: 'Zagłosuj sobie',
          done: 'Skończyłeś',
        },
        guide: {
          done: 'Zaraz wyświetlą się wyniki. Zielone paski reprezentują osoby, które ukończyły głosowanie.',
        },
        button: {
          back: 'Cofnij',
          next: 'Kolejne',
        },
        list: {
          question: 'Pytanie',
          answers: 'Odpowiedzi',
        },
      },
      results: {
        heading: {
          main: 'Wyniki głosowania',
          all: 'Wszystkie odpowiedzi',
        },
        button: {
          reset: 'Resetuj',
          all: 'Pokaż wszystkie',
        },
        list: {
          votes: 'Najwięcej głosów',
          connected: 'Najbardziej łączą',
          question: 'Pytanie',
          answers: 'Odpowiedzi',
        },
      },
      hall: {
        heading: {
          main: 'Hala Sław',
        },
        guide: {
          main: 'Uznanie dla zasłużonych, którzy używali aplikacji podczas testów. Kolejność losowa.',
        },
        button: {
          close: 'Zamknij',
        },
        label: {
          alpha: 'Uczestnicy Alpha',
          special: 'Specjalne podziękowania',
        },
      },
      hints: { ...hints.pl },
      animals: { ...animalNames.pl },
    },
  },
}
