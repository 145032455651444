export const alphaTesters = [
  'Wiktoria W.',
  'Kamil G.',
  'Michał Z.',
  'Sergiusz B.',
  'Aselia K.',
  'Micah L.',
  'Clark S.',
  'Jeffrey Y.',
  'Lina Z.',
  'Ryan Z.',
  'Laura P.',
  'Sonia K.',
  'Asia P.',
  'Daniel M.',
  'Halina C.',
  'Jagoda K.',
  'Jonatan W.',
  'Mateusz W.',
  'Maja O.',
  'Piotr C.',
  'Varvara',
  'Filip O.',
  'Justyna O.',
  'Natalia K.',
  'Михал К.',
]

export const specialThanks = ['Waldemar O.', 'Alina P.']
