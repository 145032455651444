import css from './InviteButton.module.css'
import i18next from 'i18next'

function InviteButton(props) {
  const { gameId } = props

  function fullUrl(gameId) {
    return (
      window.location.protocol + '//' + window.location.host + '/game/' + gameId
    )
  }

  function copyValue(event) {
    event.preventDefault()
    navigator.clipboard.writeText(fullUrl(gameId))
    // TODO: Add notification when Copied
    // TODO: Different behaviour on mobile & Desktop
  }

  return (
    <button className={css.InviteButton} onClick={copyValue} type={'submit'}>
      {i18next.t('lobby.button.invite.enabled')}
    </button>
  )
}

export default InviteButton
