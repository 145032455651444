import {
  calculateTopAnswerers,
  getPlayerColor,
  playerName,
  topVotedAnswersWithDetails,
} from '../../utils'
import css from './TopVotes.module.css'
import Button from '../../elements/Button/Button'
import Heading from '../../elements/Heading/Heading'
import Label from '../../elements/Label/Label'
import Player from '../../elements/Name/Player'
import i18next from 'i18next'

function TopVotes(props) {
  const { questions, players, onShowAllClick, onResetClick } = props

  return (
    <div className={css.TopVotes}>
      <Heading text={i18next.t('results.heading.main')} />
      <Label text={i18next.t('results.list.votes')} underline={true} />
      <div className={css.topAnswerer}>
        {calculateTopAnswerers(questions).map((vote) => (
          <Player
            id={vote.answererId}
            key={vote.answererId}
            name={playerName(vote.answererId, players)}
            color={getPlayerColor(vote.answererId, players)}
            number={vote.count}
          />
        ))}
      </div>

      <Label text={i18next.t('results.list.connected')} />
      <div className={css.mostStarredAnswers}>
        {topVotedAnswersWithDetails(questions).map((detail) => (
          <div
            className={css.answer}
            key={detail.questionId + ':' + detail.answererId}
          >
            <p className={css.q}>{detail.questionText}</p>
            <Player
              name={playerName(detail.answererId, players)}
              color={getPlayerColor(detail.answererId, players)}
              id={detail.answererId}
              number={detail.count}
            />
            <p className={css.a}>{detail.answerText}</p>
          </div>
        ))}
      </div>

      <div className={css.buttons}>
        <Button text={i18next.t('results.button.reset')} click={onResetClick} />
        <Button text={i18next.t('results.button.all')} click={onShowAllClick} />
      </div>
    </div>
  )
}

export default TopVotes
