import css from './Voting.module.css'
import VotableAnswers from '../../elements/VotableAnswers/VotableAnswers'
import { useState } from 'react'
import Button from '../../elements/Button/Button'
import { hasVotedOnAllQuestions, progressLineProps } from '../../utils'
import ProgressLine from '../../elements/ProgressLine/ProgressLine'
import Heading from '../../elements/Heading/Heading'
import Label from '../../elements/Label/Label'
import Guide from '../../elements/Guide/Guide'
import i18next from 'i18next'

function Voting(props) {
  const [votes, setVotes] = useState([])
  const [index, setIndex] = useState(0)
  const { players, questions, currentPlayerId, gameId, pushProgress } = props

  function nextPage() {
    if (index + 1 >= players.length) {
      return
    }
    setIndex(index + 1)
  }

  function prevPage() {
    if (index === 0) {
      return
    }
    setIndex(index - 1)
  }

  function getVote(answerProviderId) {
    // prevent voting for yourself
    if (answerProviderId === currentPlayerId) {
      return
    }

    const vote = {
      questionId: questions[index].humanId,
      answererId: answerProviderId,
    }

    const copiedVotes = [...votes]
    copiedVotes[index] = vote
    setVotes(copiedVotes)
  }

  function numberOfVotes() {
    let noOfVotes = 0

    for (const vote of votes) {
      if (
        vote?.hasOwnProperty('questionId') &&
        vote?.hasOwnProperty('answererId')
      ) {
        noOfVotes += 1
      }
    }

    return noOfVotes
  }

  async function putVotes() {
    const putVotePayload = {
      gameId,
      votes,
      voterId: currentPlayerId,
    }

    const voteUrl = `${process.env.REACT_APP_API_URL}/game/progress/question/answer/vote`
    const response = await fetch(voteUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(putVotePayload),
    })

    return await response.json()
  }

  function finishVoting() {
    putVotes().then(({ data }) => {
      pushProgress(data)
    })
  }

  function alreadyVotedAnswer() {
    if (votes[index]?.answererId) {
      return votes[index].answererId
    } else {
      return null
    }
  }

  function isNextButtonDisabled() {
    return index + 1 >= players.length || votes[index] === undefined
  }

  function renderRightButton() {
    if (numberOfVotes() === players.length && index === players.length - 1) {
      return (
        <Button click={finishVoting} text={i18next.t('voting.button.next')} />
      )
    }
    return (
      <Button
        disabled={isNextButtonDisabled()}
        click={() => nextPage()}
        text={i18next.t('voting.button.next')}
      />
    )
  }

  return (
    <div className={css.Voting}>
      {hasVotedOnAllQuestions(currentPlayerId, questions) ? (
        <div>
          <Heading text={i18next.t('voting.heading.done')} />
          <Guide text={i18next.t('voting.guide.done')} />
          <div className={css.LineWrapper}>
            <ProgressLine
              total={progressLineProps(questions).total}
              done={progressLineProps(questions).done}
            />
          </div>
        </div>
      ) : (
        <>
          <Heading text={i18next.t('voting.heading.main')} />

          <Label text={i18next.t('voting.list.question')} />
          <p className={css.question}>{questions[index].text}</p>

          <Label text={i18next.t('voting.list.answers')} />
          <VotableAnswers
            question={questions[index]}
            pushVote={getVote}
            voterId={currentPlayerId}
            players={players}
            alreadySelectedId={alreadyVotedAnswer()}
          />

          <div className={css.buttons}>
            <Button
              disabled={index === 0}
              click={() => prevPage()}
              text={i18next.t('voting.button.back')}
            />
            {renderRightButton()}
          </div>
        </>
      )}
    </div>
  )
}

export default Voting
