export const hintsKeys = [
  'biggestAchievement',
  'valuedTrait',
  'futureGoal',
  'greatestStrength',
  'importantLesson',
  'proudOf',
  'keyValues',
  'nearestGoals',
  'feelAlive',
  'yearLeft',
  'dreams',
  'futureFears',
  'lifeExperience',
  'selfAcceptance',
  'dailyJoy',
  'biggestInfluence',
  'actOfKindness',
  'perfectDay',
  'makeYouSmile',
  'surprisedByOthers',
  'wantToLearn',
  'wantToTeach',
  'reliveMoment',
  'impact',
  'freedomMeaning',
  'desiredEmotion',
  'adviceToYoungerSelf',
  'adviceToFutureSelf',
  'personalMystery',
  'misunderstoodByOthers',
  'dinnerGuests',
  'perfectDayDescription',
  'newHabit',
  'happinessInWords',
  'feelConnected',
  'colorAsSelf',
  'noJudgment',
  'giftToWorld',
  'currentCuriosity',
  'preferredEra',
  'questionToAnswer',
  'mustExperience',
  'changeInTreatment',
  'newSkill',
  'uniqueBelief',
  'favoriteMemory',
  'lifeBookTitle',
  'rewindOrFastForward',
]

export const hints = {
  en: {
    biggestAchievement: 'What is your biggest achievement?',
    valuedTrait: 'What trait do you value most in others?',
    futureGoal: 'What would you like to achieve?',
    greatestStrength: 'What is your greatest strength?',
    importantLesson: 'What is the most important thing you have learned?',
    proudOf: 'What are you most proud of?',
    keyValues: 'What are your three most important values?',
    nearestGoals: 'What are your nearest goals?',
    feelAlive: 'What makes you feel truly alive?',
    yearLeft: 'If you had one year left to live, what would you do?',
    dreams: 'What do you dream about?',
    futureFears: 'What are you afraid of when you think about the future?',
    lifeExperience: 'What do you hope to experience by the end of your life?',
    selfAcceptance: 'What do you not accept about yourself?',
    dailyJoy: 'What brings you daily joy?',
    biggestInfluence: 'Who has influenced your life the most, and why?',
    actOfKindness:
      'What act of kindness were you once offered, and why was it important to you?',
    perfectDay: 'What is your favorite way to spend a day?',
    makeYouSmile: 'What things make you smile?',
    surprisedByOthers: 'What surprises you the most about other people?',
    wantToLearn: 'What would you like to learn?',
    wantToTeach: 'What would you like to teach others?',
    reliveMoment: 'If you could relive one moment, which would it be, and why?',
    impact: 'What kind of impact do you want to leave behind?',
    freedomMeaning: 'What does freedom mean to you?',
    desiredEmotion: 'What emotion do you wish you could feel more often?',
    adviceToYoungerSelf:
      'What would you tell your younger self if you had the chance?',
    adviceToFutureSelf:
      'What would you tell your future-self if you had the chance?',
    personalMystery:
      'What’s something mysterious about you that even you don’t fully understand?',
    misunderstoodByOthers:
      'What do you think people misunderstand most about you?',
    dinnerGuests:
      'If you could invite three people, living or dead, to dinner, who would they be and why?',
    perfectDayDescription:
      'What would a perfect day look like for you from start to finish?',
    newHabit: 'What’s a small habit you wish you could adopt every day?',
    happinessInWords: 'How would you describe happiness in just three words?',
    feelConnected: 'What makes you feel connected to others?',
    colorAsSelf:
      'If you had to describe yourself as a color, which would you choose and why?',
    noJudgment:
      'What would you do differently if you knew no one would judge you?',
    giftToWorld:
      'If you could give everyone in the world one quality, what would it be and why?',
    currentCuriosity: 'What’s one thing you’re really curious about right now?',
    preferredEra:
      'If you could live in any era of history, which one would you choose and why?',
    questionToAnswer: 'What’s a question you would love to know the answer to?',
    mustExperience:
      'What’s one experience you think everyone should have in their life?',
    changeInTreatment:
      'If you could change one thing about how people treat each other, what would it be?',
    newSkill:
      'If you could wake up tomorrow with one new skill, what would it be?',
    uniqueBelief:
      'What’s something you believe that others might find unusual?',
    favoriteMemory: 'What’s your favorite memory?',
    lifeBookTitle: 'If your life were a book, what title would you give it?',
    rewindOrFastForward:
      'Would you rather rewind or fast-forward time? Describe why.',
  },
  pl: {
    biggestAchievement: 'Jaki jest Twój największy sukces?',
    valuedTrait: 'Jaką cechę najbardziej cenisz w innych?',
    futureGoal: 'Co chciałbyś osiągnąć?',
    greatestStrength: 'Co jest Twoją największą zaletą?',
    importantLesson: 'Jaka jest najważniejsza rzecz, jakiej się nauczyłeś?',
    proudOf: 'Z czego jesteś najbardziej dumny?',
    keyValues: 'Jakie są Twoje trzy najważniejsze wartości?',
    nearestGoals: 'Jakie są Twoje najbliższe cele?',
    feelAlive: 'Co sprawia, że czujesz, że żyjesz?',
    yearLeft: 'Gdyby został Ci rok życia, to co byś wtedy zrobił?',
    dreams: 'O czym marzysz?',
    futureFears: 'Czego się boisz myśląc o przyszłości?',
    lifeExperience: 'Czego masz nadzieję doświadczyć pod koniec swojego życia?',
    selfAcceptance: 'Czego w sobie nie akceptujesz?',
    dailyJoy: 'Co sprawia Ci radość na codzień?',
    biggestInfluence: 'Kto i dlaczego wywarł wpływ na Twoje życie?',
    actOfKindness:
      'Jaki akt dobroci został Ci kiedyś oferowany i dlaczego był dla Ciebie ważny?',
    perfectDay: 'Jaki jest Twój ulubiony sposób spędzania dnia?',
    makeYouSmile: 'Jakie rzeczy sprawiają, że się uśmiechasz?',
    surprisedByOthers: 'Co Cię najbardziej zaskakuje u innych ludzi?',
    wantToLearn: 'Czego chciałbyś się nauczyć?',
    wantToTeach: 'Czego chciałbyś nauczyć innych?',
    reliveMoment:
      'Gdybyś mógł przeżyć jeden moment jeszcze raz, jaki by to był i dlaczego?',
    impact: 'Jaki wpływ chciałbyś pozostawić po sobie?',
    freedomMeaning: 'Co dla Ciebie oznacza wolność?',
    desiredEmotion: 'Jakie emocje chciałbyś częściej odczuwać?',
    adviceToYoungerSelf:
      'Co powiedziałbyś sobie z przeszłości, gdybyś miał taką szansę?',
    adviceToFutureSelf:
      'Co powiedziałbyś sobie z przyszłości, gdybyś miał taką szansę?',
    personalMystery:
      'Co jest w Tobie tajemnicze, czego nawet Ty nie do końca rozumiesz?',
    misunderstoodByOthers:
      'Co Twoim zdaniem ludzie najczęściej źle o Tobie rozumieją?',
    dinnerGuests:
      'Gdybyś mógł zaprosić na kolację trzy osoby, żywe lub zmarłe, kto by to był i dlaczego?',
    perfectDayDescription:
      'Jak wyglądałby Twój idealny dzień od początku do końca?',
    newHabit: 'Jaki drobny nawyk chciałbyś przyjąć każdego dnia?',
    happinessInWords: 'Jak opisałbyś szczęście w trzech słowach?',
    feelConnected: 'Co sprawia, że czujesz się połączony z innymi?',
    colorAsSelf:
      'Gdybyś miał opisać siebie jako kolor, jaki byś wybrał i dlaczego?',
    noJudgment:
      'Co byś zrobił inaczej, gdybyś wiedział, że nikt Cię nie oceni?',
    giftToWorld:
      'Gdybyś mógł dać wszystkim ludziom na świecie jedną cechę, co by to było i dlaczego?',
    currentCuriosity: 'Co najbardziej ciekawi Cię obecnie?',
    preferredEra:
      'Gdybyś mógł żyć w dowolnej epoce historycznej, którą byś wybrał i dlaczego?',
    questionToAnswer: 'Na jakie pytanie bardzo chciałbyś poznać odpowiedź?',
    mustExperience:
      'Jaka jest jedna rzecz, której uważasz, że każdy powinien doświadczyć w swoim życiu?',
    changeInTreatment:
      'Gdybyś mógł zmienić jedną rzecz w sposobie, w jaki ludzie się traktują, co by to było?',
    newSkill:
      'Gdybyś mógł obudzić się jutro z nową umiejętnością, jaka by to była?',
    uniqueBelief: 'W co wierzysz, co inni mogą uznać za nietypowe?',
    favoriteMemory: 'Jakie jest Twoje ulubione wspomnienie?',
    lifeBookTitle: 'Gdyby Twoje życie było książką, jaki nadałbyś jej tytuł?',
    rewindOrFastForward:
      'Wolałbyś cofać, czy przyśpieszać czas? Opisz dlaczego.',
  },
}
