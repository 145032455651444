import css from './Guide.module.css'

function Guide(props) {
  const { text = '' } = props
  return (
    <div className={css.Guide}>
      <p>{text}</p>
    </div>
  )
}

export default Guide
