import css from './SingleInputForm.module.css'
import { useState } from 'react'

function SingleInputForm(props) {
  const {
    callback,
    buttonText = '',
    buttonTextDisabled = '',
    placeholder = '',
    label = '',
  } = props
  const [value, setValue] = useState('')
  const [submitted, setSubmitted] = useState(false)

  function updateInput(event) {
    setValue(event.target.value)
  }

  function handleSubmit(event) {
    event.preventDefault()
    setSubmitted(true)
    callback(value)
  }

  function isDisabled() {
    return value.trim().length <= 1
  }

  return (
    <form className={css.SingleInputForm} onSubmit={handleSubmit}>
      <div className={css.top}>
        <label htmlFor={'name'}>{label}</label>
        <input
          name="name"
          value={value}
          placeholder={placeholder}
          onChange={updateInput}
          maxLength={24}
        />
      </div>
      <div className={css.bot}>
        <button type={'submit'} disabled={isDisabled() || submitted}>
          {isDisabled() ? buttonTextDisabled : buttonText}
        </button>
      </div>
    </form>
  )
}

export default SingleInputForm
